import React, { useEffect, useState } from 'react';

import { getBytesStrapiUrl } from '@lib/bytesStrapiServices';
import { dateFormat } from '@utils/functions/readingTime';
import axios from 'axios';
import { useRouter } from 'next/router';
import CustomStripBanner from './CustomStripBanner';
import dynamic from 'next/dynamic';

const DemoClassStrip = dynamic(() => import('@components/shared/header/DemoClassStrip'), {
  ssr: false,
});

const EventStripBanner = dynamic(() => import('@components/shared/header/StripBanner'), {
  ssr: false,
});

async function fetchCampaignData(slug) {
  try {
    const apiBaseUrl = getBytesStrapiUrl();

    const date = new Date();

    const today = dateFormat(date);

    let URL = `${apiBaseUrl}/api/strip-banners?populate=pages&filters[$and][0][expiryDate][$gte]=${today}`;

    if(slug === "home") {
      URL += `&filters[$and][0][pages][slug][$eq]=${slug}`
    } else {
      URL += `&filters[$and][0][pages][slug][$contains]=${slug}`
    }

    const { data } = await axios.get(URL);

    const campaignData = data?.data[0]?.attributes;

    if (!campaignData) {
      return null;
    }

    return campaignData;
  } catch (error) {
    console.log('CAMPAIGN_STRIP', error);
  }
}

function DynamicStripBanner({ isDemoClass = false }) {
  const [campaignData, setCampaignData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const router = useRouter();

  const fetchData = async () => {
    try {
      const endpoint = router.asPath.split('/').pop();

      const data = await fetchCampaignData(endpoint || 'home');

      setCampaignData(data);
    } catch (err) {
      console.log('FETCH_CAMP_ERR', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      setCampaignData(null);
      setLoading(true);
    };
  }, [router]);

  if (isLoading) return <></>;

  if (!!campaignData) {
    return <CustomStripBanner campaignData={campaignData} />;
  }

  if (isDemoClass) {
    return <DemoClassStrip />;
  }

  return <EventStripBanner />;
}

export default DynamicStripBanner;
